@import "../../theme.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  background-color: $background-color;
  border-radius: $border-radius;
  opacity: 0.5;
}

.modal {
  background: $background-color;
  border: 1px solid $border-color;
  text-align: center;
  z-index: 1;

  position: fixed;
  left: 10vw;
  top: 50%;
  transform: translateY(-50%);
  height: 50vh;
  width: 80vw;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 2rem;
  }
}
