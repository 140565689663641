$background-color: #131723;
$border-color: #202834;
$font-family: sans-serif;

$primary-text-color: #adb1b7;
$secondary-text-color: #3e4451;

$asks-text-color: #ab2f32;
$asks-graph-color: #3d1e28;
$bids-text-color: #108860;
$bids-graph-color: #123534;

$accent-color: #5741d9;

$border-radius: 3px;
