@import "reset-css";

@import "theme.scss";

html {
  background: $background-color;
}

h1,
p {
  color: $primary-text-color;
}

h1,
button,
p,
span {
  font-family: $font-family;
}
