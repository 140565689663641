@import "../theme.scss";

.orderList {
  border-collapse: collapse;
  display: flex;
  flex-wrap: wrap;
  font-family: monospace;
  text-align: right;
}

.row {
  box-sizing: border-box;
  display: flex;
  padding: 0.2rem 3rem;
  width: 100%;
}

.headerRow {
  order: 0;
}

.cell {
  width: 33.333%;
}

.headingCell {
  color: $secondary-text-color;
  text-transform: uppercase;
}

.asksRow {
  background: linear-gradient(
    to right,
    $asks-graph-color var(--stop),
    transparent var(--stop) 100%
  );
}

.bidsRow {
  background: linear-gradient(
    to left,
    $bids-graph-color var(--stop),
    transparent var(--stop) 100%
  );
}

.orderValue {
  color: $primary-text-color;
}

.asksPrice {
  color: $asks-text-color;
}

.bidsPrice {
  color: $bids-text-color;
}

@media (max-width: 600px), screen and (orientation: portrait) {
  .row {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .headerRow {
    order: 1;
  }

  .bidsList {
    .row {
      flex-direction: row-reverse;
    }
    .headerRow {
      display: none;
    }
  }

  .bidsRow {
    background: linear-gradient(
      to right,
      $bids-graph-color var(--stop),
      transparent var(--stop) 100%
    );
  }
}
